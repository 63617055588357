@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
.hero {
    width: 100vw;
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
}
.hero-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    @include mobile {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
    }
}
.hero-container-left {
    flex: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include mobile {
        justify-content: flex-start;
        margin-top: 1rem;
    }
    .info-container {
        display: flex;
        flex-direction: row;

        .infos {
            display: flex;
            flex-direction: column;
        }
        .social {
            display: flex;
            flex-direction: column;
        }
        .social > * {
            margin: 1rem;
            cursor: pointer;
            @include mobile {
                margin: 0.6rem;
            }
        }
    }
    h1 {
        color: white;
        letter-spacing: 0.1rem;
        font-weight: 600;
        margin: 1rem;
        font-size: 3.2rem;
        text-shadow: 0 1px 1px white, 0 1px 1px white, 0 0 1px white;
        overflow-y: hidden;
        @include mobile {
            font-size: 2rem;
            margin: 0.6rem;
        }
    }
    span {
        font-weight: 450;
        margin: 1rem;
        color: #f1a100;
        text-shadow:
          /* White glow */ 0 0 2px #f1a100, 0 0 2px #f1a100, 0 0 2px #f1a100;
        @include mobile {
            margin: 0.6rem;
        }
    }
    p {
        color: white;
        font-weight: 450;
        margin: 1rem;
        text-shadow: 0 0px 1px white, 0 0px 1px white, 0 0 1px white;
        @include mobile {
            margin: 0.6rem;
        }
    }
    .button-aboutme {
        width: 35%;
        height: 48px;
        color: white;
        background-color: #f1a100;
        border: 0px;
        border-radius: 4px;
        font-size: 16px;
        margin: 1rem;
        cursor: pointer;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-shadow:
          /* White glow */ 0 0 1px white, 0 0 1px white, 0 0 1px white;
        letter-spacing: 1px;
        @include mobile {
            width: 42%;

            margin: 0.6rem;
        }
    }
}
.hero-container-right {
    flex: 4;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include mobile {
        align-items: center;
    }
    .photo-container {
        position: relative;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            width: 168px;
            height: 200px;
        }
        img {
            width: 94%;
            height: 92%;
            object-fit: cover;
            border-radius: 50%;

            @include mobile {
                width: 100%;
                padding: 0.9rem;
                object-fit: contain;
            }
        }
    }
    .glow::before,
    .glow::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        background: linear-gradient(90deg, #f1a100, #e9c478, #fe7f00, #edeae8);
        background-size: 400%;
        width: calc(100%);
        height: calc(100%);
        z-index: -1;
        animation: animas 20s linear infinite;
        @include mobile {
            animation: animas 15s linear infinite;
        }
    }
    @keyframes animas {
        0% {
            background-position: 100% 200%;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 100% 300%;
        }
    }
    .glow::after {
        filter: blur(24px);
        border-radius: 50%;
        opacity: 0.5;
    }
}

.scroll-button {
    position: relative;
    bottom: 2%;
    left: 45%;
    animation: scrollAnimate 0.5s infinite alternate;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
@keyframes scrollAnimate {
    from {
        bottom: 1%;
    }
    to {
        bottom: 3%;
    }
}
