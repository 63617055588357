@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.contact {
    width: 100vw;
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
.contact-wrapper {
    width: 100%;
    height: 94vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
.left-section {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mobile {
        width: 100%;
        height: auto;
        margin-top: 20%;
    }
    h2 {
        color: $base-color-orange;
        margin-top: 0;
    }
    h1 {
        color: white;
        margin-top: 0;
    }
    p {
        color: white;
        margin-top: 0;
    }
}
.left-section * {
    margin-left: 50%;
    margin-bottom: 0.5rem;
    @include mobile {
        margin-left: 2rem;
    }
}
.right-section {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
    margin-left: 2rem;
    @include mobile {
        width: 100%;
        height: 50%;
        margin-left: 2rem;
    }
    input {
        width: 50%;
        height: 6vh;
        margin: 0.3rem;
        border-radius: 8px;
        border: 1px solid $base-color-orange;
        background-color: whitesmoke;
        @include mobile {
            width: 75%;
            height: 15%;
            margin: 0.2rem;
        }
    }
    button {
        width: 28%;
        height: 5vh;
        margin: 0.5rem;
        border-radius: 8px;
        background-color: $base-color-orange;
        color: white;
        border: 1px solid $base-color-orange;
        font-size: 1rem;
        cursor: pointer;
        @include mobile {
            margin: 0.2rem;
        }
    }
    span {
        overflow: hidden;
        color: $base-color-orange;
        margin: 0.3rem;
        @include mobile {
            margin: 0rem;
        }
    }
}
.footer {
    width: 100%;
    height: 6vh;
    display: flex;
    justify-content: center;
    span {
        text-align: center;
        color: white;
        margin: 0.5rem;
        font-size: 0.8rem;
    }
}
.scrolltop {
    position: relative;
    height: 12vh;
    left: 40%;
    z-index: 999;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    animation: jump 0.5s infinite alternate;
    @include mobile {
        left: 45%;
    }
}
@keyframes jump {
    from {
        bottom: 1%;
    }
    to {
        bottom: 3%;
    }
}
.input-container-captcha {
    width: 50%;
    height: 6vh;
    margin: 0.3rem;
    border-radius: 8px;
    border: 1px solid $base-color-orange;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.4rem;

    @include mobile {
        width: 75%;
        height: 15%;
        margin: 0.2rem;
    }
}
.input-container {
    width: 50%;
    height: 6vh;
    margin: 0.3rem;
    border-radius: 8px;
    border: 1px solid $base-color-orange;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.4rem;
    @include mobile {
        width: 75%;
        height: 15%;
        margin: 0.2rem;
    }
    input {
        width: 100%;
        height: 100%;
        border: 0px;
        background-color: transparent;
        &:focus {
            outline: none;
        }
    }
}
