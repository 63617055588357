@import "../../../../styles/mixins.scss";

.skill-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100px;
    max-width: 140px;
    min-width: 130px;
    margin: 0.5rem;
    margin-top: 0.75rem;
    @include mobile {
        max-height: 68px;
        min-width: 70px;
        margin: 0.5rem;
    }
}
.skill-img-container {
    overflow-y: hidden;
    flex: 1;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.skill-infos {
    p,
    span {
        text-align: center;
        color: white;
        margin-top: 0.5rem;
        font-size: 1rem;
        text-shadow:
        /* White glow */ 0 0 2px #f1a100, 0 0 2px #f1a100, 0 0 2px #f1a100;
        @include mobile {
            font-size: 0.9rem;
            margin-top: 0.5rem;
        }
    }
}
