@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.aboutme {
    width: 100vw;
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile {
        justify-content: flex-start;
    }
}
.wrapper {
    height: 40vh;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    justify-content: center;
    @include mobile {
        margin-top: 0.5rem;
        height: 38vh;
    }
}
.progress-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    overflow-y: hidden;
    @include mobile {
        margin-left: 0.5rem;
    }
}
.progress-circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
}
.progress-stick {
    width: 1px;
    height: 100%;
    background: rgb(255, 255, 255);
    background: rgb(0, 0, 0);
    background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.4543067226890757) 10%,
        rgba(0, 0, 0, 0.4543067226890757) 21%,
        rgba(255, 255, 255, 1) 41%
    );
}
.aboutme-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 1rem;
    overflow-y: hidden;
    @include mobile {
    }
    h2 {
        color: $base-color-orange;
        padding: 0;
        margin: 0;
    }
    h1 {
        color: white;
        padding: 0;
        margin: 0;
        @include mobile {
            font-size: 1.4rem;
        }
    }
    p {
        color: white;
        padding: 0;
        margin-top: 0.5rem;
        @include mobile {
            margin-top: 0.3rem;
            font-size: 0.9rem;
        }
    }

    a {
        overflow-y: hidden;
        width: 20%;
        height: 15%;
        margin-top: 1rem;
        border: 0px;
        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 1.1rem;
        padding: 0.2rem;
        background-color: $base-color-orange;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
            width: 40%;
            height: 15%;
            margin-top: 0.5rem;
        }
    }
}

.skill-container {
    overflow-y: hidden;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
        height: 54vh;
    }
}
