@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.works {
    width: 100vw;
    height: 100vh;
    scroll-snap-align: center;
}
.works-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile {
        justify-content: flex-start;
    }
}
.works-upper-section {
    width: 50%;
    height: 20vh;

    h2 {
        color: $base-color-orange;
        margin: 0;
    }
    h1 {
        color: white;
        margin: 0;
    }
    p {
        color: whitesmoke;
        margin: 0;
    }
    @include mobile {
        width: 80%;
        margin-top: 2rem;
    }
}
.works-upper-section * {
    margin-top: 0.4rem;
}
.works-bottom-section {
    width: 100%;
    height: 58vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow-y: hidden;
    @include mobile {
        width: 100%;
        height: 60vh;
    }
}

.arrow {
    font-size: 2rem;
    cursor: pointer;
    color: white;
}
.arrow::after {
    opacity: 0.5;
    color: red;
}
.arrow:active {
    padding: 100px;
    margin: 100px;
    opacity: 1;
    transition: 1s;
}
