@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
.testimonial {
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include mobile {
        width: 80%;
    }
}
.testimonial-wrapper {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(16, 16, 16);

    overflow-y: hidden;
    cursor: pointer;
    position: relative;
    @include mobile {
        width: 100%;
    }
}
.testimonial-image-container {
    flex: 2;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.8rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include mobile {
        }
    }
}

.testimonial-infos {
    flex: 1;
    margin-left: 0.8rem;
    h3 {
        color: $base-color-orange;
    }
    p {
        color: white;
    }
    span {
        color: white;
    }
}
.testimonial-infos * {
    margin: 0.5rem;
    @include mobile {
        margin: 0.3rem;
    }
}
.testimonial-tags {
    overflow-y: hidden;
}
.github-icon {
    position: absolute;
    z-index: 999;
    top: 10px;
    right: 10px;
}
.search-icon {
    position: absolute;
    z-index: 999;
    top: 10px;
    left: 10px;
    a {
        text-decoration: none;
        color: white;
    }
    @include mobile {
        top: 10px;
    }
}
