.App {
    height: 100vh;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    background-color:#0a0909  ;

}
.App::-webkit-scrollbar {
    display: none;
}
